import * as React from 'react';
import styled from 'styled-components';

import { HeaderH3 } from './Typography';

const ContainerRoot = styled.div({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	marginTop: '2rem',
	marginBottom: '3rem',
});

const ContainerVideo = styled.div({
	position: 'relative',
	width: '100%',
	height: '0',
	paddingBottom: '56.25%',
});

interface IVideoProps {
	header?: string;
	source: string;
	videoTitle?: string;
}

export const VideoContainer: React.FC<IVideoProps> = props => {
	const { header, source, videoTitle } = props;

	return (
		<ContainerRoot>
			<HeaderH3 style={{ marginBottom: '2rem' }}>{header}</HeaderH3>
			<ContainerVideo className="video">
				<iframe
					src={source}
					title={videoTitle}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					allowFullScreen
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						borderRadius: '0.5rem',
					}}
				/>
			</ContainerVideo>
		</ContainerRoot>
	);
};
