import styled from 'styled-components';

import { getEmSize } from '../styles/mixins';
import { widths } from '../styles/variables';

export const ColorContainer = styled.div<{ backgroundColor?: string }>(props => {
	return {
		backgroundColor: props.backgroundColor ?? 'white',
		padding: '20px 0',
	};
});

export const Container = styled.div({
	position: `relative`,
	marginLeft: `auto`,
	marginRight: `auto`,
	width: `auto`,
	maxWidth: `${getEmSize(widths.lg)}em`,
	paddingLeft: 16,
	paddingRight: 16,
});

export const Row = styled.div<{ flex?: number | boolean }>(props => ({
	display: 'flex',
	flexDirection: 'row',
	flex: typeof props.flex === 'boolean' ? 1 : props.flex,
}));

export const Column = styled.div<{ flex?: number | boolean }>(props => ({
	display: 'flex',
	flexDirection: 'column',
	flex: typeof props.flex === 'boolean' ? 1 : props.flex,
	minWidth: 300,
}));
