import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { BlogImageWrapper } from "../../../../src/components/BlogImageWrapper";
import * as React from 'react';
export default {
  graphql,
  getImage,
  GatsbyImage,
  BlogImageWrapper,
  React
};