/**
 * Image Wrapper Grid for blog posts
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */
import * as React from 'react';
import styled from 'styled-components';

const StyledBlogPostImage = styled.div({
	display: 'grid',
	gridTemplateColumns: '48% 48%',
	columnGap: '4%',
});

export const BlogImageWrapper: React.FC = props => {
	const { children } = props;

	return <StyledBlogPostImage>{children}</StyledBlogPostImage>;
};
