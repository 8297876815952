import styled from 'styled-components';

import lightingColors from '../styles/lighting';

export const HeaderH2 = styled.h2({});

export const HeaderH3 = styled.h3({});

export const HeaderH4 = styled.h4({});

export const HeaderH5 = styled.h5({});

export const Subtitle = styled.h6({});

export const Body = styled.p<{ maxWidth?: number }>(props => ({
	maxWidth: props.maxWidth ? props.maxWidth : 400,
}));

export const Footnote = styled.p({
	textAlign: 'center',
	fontSize: '1rem',
});

export const LocalLink = styled.a({
	textDecoration: 'none !important',
	'&:hover': {
		color: lightingColors.highlight.orange,
	},
});

export const WideBody = styled.p({
	fontSize: '1rem',
	lineHeight: '1.5rem',
});

export const Title = styled.div({
	marginTop: '6rem',
	marginBottom: '4rem',
});
