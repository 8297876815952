/**
 * colors
 *
 * @author Anja Bisako <anja.bisako@thingos.io>
 * @version 0.0.1
 */

const lightingColors = {
	background: {
		light: '#171635',
		dark: '#161333',
		card: '#11102B',
	},
	highlight: {
		orange: '#f99b43',
		pink: '#f72580',
		blue: '#204af7',
		mint: '#25f7dd',
	},

	white: '#ffffff',
};

export default lightingColors;
